.wrapper {
    /* #251c24; */
    background-color: #303030; 
    padding: 2rem;
    position: relative;

    box-shadow: inset 0 1rem 12px -12px #1b1b1b;
}

/* .wrapper::after {
    content: "";
    display: table;
    position: absolute;
    box-shadow: inset 0 5px 1rem #1a1a1a;
    width: 110vw;
    left: -5vw;
    height: 100%;
    top: 0;
} */

.content {
    display: grid;
    gap: .9rem;
    margin: 0 auto;
    padding: 2rem;
}